<template>
  <div id="start-up-box">
    <div :class="isLogo ? 'logo logo-active' : 'logo'">
      <img
        class="logoimg"
        v-if="!isLogo"
        src="../../assets/images/logo.ico.png"
        alt=""
      />
      <!-- <img src="../../assets/images/tklogo.png" alt="" class="logo1" /> -->
      <!-- <img src="../../assets/images/TIKTOK.png" alt="" class="tiktok">
            <img src="../../assets/images/shop.png" alt="" class="shop"> -->
    </div>
    <div class="logo-content">
      <!-- <h1 class="app-name">
                {{$t('logo.name')}}
            </h1> -->
      <!-- <p class="msg">{{$t('logo.msg')}}</p> -->
    </div>
  </div>
</template>
<script>
const noop = () => { }; // 定义一个空函数
noop(); // 调用它不做任何事情
export default {
  name: "logo",
  data() {
    return {
      isLogo: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLogo = true;
      setTimeout(() => {
        // this.$router.push('/home')
      }, 800);
    }, 500);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#start-up-box {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #ff8911;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 76px;
    height: 76px;
    margin-bottom: 64px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    .tiktok,
    .logo1,
    .shop {
      transition: all 0.3s linear;
      width: 0px;
      opacity: 0;
    }
    .logoimg {
      transition: all 0.3s linear;
      opacity: 1;
    }
  }
  .logo-active {
    width: 180px;
    height: 180px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tiktok {
      width: 118px;
      margin: 12px 0 7px 0;
    }
    .shop {
      width: 74px;
    }
    .logo1 {
      width: 100%;
    }
    .tiktok,
    .logo1,
    .shop {
      opacity: 1;
    }
  }

  .logo-content {
    text-align: center;
  }

  .logo-content {
    color: rgba(255, 255, 255, 0.8);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    margin-bottom: 130px;

    .app-name {
      color: #fff;
      font-family: Inter;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .url {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 19px;
  }
}
</style>
